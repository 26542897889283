<template>
  <div id="infoDetail">
    <div style="background:#f5f5f5;" class="w100">
      <div class="breadNav">
        <div class="nav_info flex">
          您的位置：
          <a class="active" @click="goHome">首页</a> > <a @click="goInfoList">资讯</a> > 资讯详情
        </div>
      </div>
      <div class="infoDetail flex">
        <div class="leftInfoBox">
          <div style="height:40px"></div>
          <div class="container">
            <div class="detail">
              <p class="title">标题标题标题标题标题标题标题标题标题标题标题标题</p>
              <p class="f12 gray">
                <span class="time">2019-04-19</span>
                <img src alt>
                <span>2428人浏览</span>
              </p>
              <p class="newsContent">
                新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容
                新闻内容新闻内容新闻内容新新闻内容闻内容新闻内容新闻内容
                新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容
                新闻内容新闻内容新闻内容新闻内容新闻内容
              </p>
              <p class="imgBox">详情图</p>
              <p class="newsContent">
                新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容
                新闻内容新闻内容新闻内容新新闻内容闻内容新闻内容新闻内容
                新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容
                新闻内容新闻内容新闻内容新闻内容新闻内容
              </p>
              <p class="imgBox">详情图</p>
              <p class="newsContent">
                新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容
                新闻内容新闻内容新闻内容新新闻内容闻内容新闻内容新闻内容
                新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容
                新闻内容新闻内容新闻内容新闻内容新闻内容
              </p>
            </div>
          </div>
        </div>
        <div class="rightInfoBox">
          <p>热文推荐</p>
          <Info></Info>
        </div>
      </div>
      <div class="bottom">
        <Pagination></Pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Info from "@/components/common/info";
export default {
  components: { Info },
  data() {
    return {};
  },
  methods: {
    goHome() {
      this.$router.push({
        path: "/"
      });
    },
    goInfoList(){
        this.$router.push({
        path: "infoList"
      });
    },
    // 跳转到详情
    goDetailPage() {
      this.$router.push({
        path: "infoDetail"
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/change.scss';
#infoDetail{
  margin-top: 250px;
}

.leftInfoBox {
  background-color: #fff;
  margin-right: 15px;
  margin-bottom: 50px;
}
.infoDetail {
  width: 1200px;
  min-height: 500px;
  margin: 10px auto 0;
  .detail {
    padding: 20px 50px;
    .title {
      padding-bottom: 10px;
    }
    .time {
      padding-right: 20px;
    }
    .newsContent {
      padding-top: 20px;
      line-height: 20px;
      color: #888;
      font-size: 14px;
    }
    .imgBox {
      padding: 100px;
      margin: 20px 0 0;
      background: #999;
      text-align: center;
      color: #fff;
    }
  }
}

.rightInfoBox {
  p {
    padding-bottom:20px;
  }
}

</style>
